/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useNavigate } from "react-router-dom";

import { Fragment } from "react";
import { Form, Input } from "antd";
import axiosInstance from "axiosApi";
import { setTokens } from "tokenHelper";


// reactstrap components
import {
  Container,
  Col,
  Row,
  Button
} from "reactstrap";

function Login() {
  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const response = await axiosInstance.post("/token/", {
        email: values.email,
        password: values.password,
      });
      axiosInstance.defaults.headers["Authorization"] = "JWT " + response.data.access;
      setTokens(response.data);
      navigate("/admin/dashboard");
      return response;
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div className="login-page">
      <Fragment>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
          <Form name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
            <Row pt={4} pb={3} px={3}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email address" />
              </Form.Item>

              <Form.Item label="Password" name="password" rules={[{ required: true }]}>
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Row>
            <Row mt={3} mb={1} textAlign="center">
              <Form.Item>
                <Button type="primary" htmlType="submit" className="login_submit_btn">
                  Login
                </Button>
              </Form.Item>
            </Row>
          </Form>
          </Col>
        </Row>
      </Container>
      </Fragment>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/Genoloens-horizontal.png")})`,
        }}
      />
    </div>
  );
}

export default Login;
