import { useEffect, useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import Utils from "services/Utils";
import {
  Row,
  Col,
  Button
} from "reactstrap";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField, Typography
} from "@mui/material";
import moment from "moment";
import { Chrono } from "react-chrono";
import APIService from "services/DjangoAPI";

const ProjectHistory = () => {
  const { id } = useParams();
  const [statusOptions, setStatusOptions] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingProject, setLoadingProject] = useState(false);
  const [project, setProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [statusToChange, setStatusToChange] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");

  const fetchProject = async () => {
    setLoadingProject(true);
    try {
      const { data } = await APIService.getProject(id);
      setProject(data);
    } catch (e) {
      console.error("Error fetching project. Details: " + e)
    } finally {
      setLoadingProject(false);
    }
  }

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const {data} = await APIService.getProjectHistory(id)
      console.log("eventsData", data);
      const transformedEvents = data.map(event => ({
        cardTitle: event.status,
        title: new Date(event.change_date).toLocaleDateString(),
        cardSubtitle: `Changed by : ${event.changed_by.first_name} ${event.changed_by.last_name}`,
        cardDetailedText: `Comment: ${event.comment}`,
      }));
      setEvents(transformedEvents);
      if (data.length > 0) {
        setCurrentStatus(data[0].status)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleStatusChange = async (newStatus) => {
    try {
      await APIService.putStatusChangeForProject(id,{
        new_status: newStatus,
        comment: comment,
      });
      fetchEvents(); // Refresh events after status update
      alert(`Status updated to ${newStatus} successfully`);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating project status:", error);
      alert("Failed to update project status");
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchProject()
  }, [id]);

  const getNextStatus = () => {
    const statusOrder = [
      "PENDING",
      "SAMPLES_RECEIVED",
      "PREPARING_LIBRARIES",
      "SEQUENCING",
      "BIOINFO_ANALYSIS_STARTED"
    ];
    const currentIndex = statusOrder.indexOf(currentStatus);
    if (currentIndex !== -1 && currentIndex < statusOrder.length - 1) {
      return statusOrder[currentIndex + 1];
    }
    return null;
  };

  const nextStatus = getNextStatus();

  return (

    <div className="content">
      <Row>
        <h1>Project management</h1>
      </Row>
      <Row>
        <h5>As the preparation of libraries, sequencing and analysis process are usually handled for a library
          that can contain several projects, except specific cases, please go to the library page to handle those status.</h5>
      </Row>
      {!loadingProject && (
        <Row>
          <Col>
            {project && project.library ? (
              <Box>

              <Link to={`/admin/libraries/${project.library.id}`}>
                <Button color="secondary">
                  Go to Library
                </Button>
              </Link>
              </Box>
            ) : (
              <Box>
              <Typography variant="body2" color="textSecondary">Your project isn't attached to any library yet ! </Typography>
              <Link to="/admin/libraries">
                <Button color="secondary">
                  Go to Libraries List
                </Button>
              </Link>
              </Box>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col lg="3" md="4" sm="6">
          <Button
            color="info"
            onClick={() => { setStatusToChange("SAMPLES_RECEIVED"); setIsModalOpen(true); }}
            disabled={nextStatus !== "SAMPLES_RECEIVED"}
          >
            Samples Received
          </Button>
        </Col>
        <Col lg="3" md="4" sm="6">
          <Button
            color="info"
            onClick={() => { setStatusToChange("PREPARING_LIBRARIES"); setIsModalOpen(true); }}
            disabled={nextStatus !== "PREPARING_LIBRARIES"}
          >
            Preparing Libraries
          </Button>
        </Col>
        <Col lg="3" md="4" sm="6">
          <Button
            color="info"
            onClick={() => { setStatusToChange("SEQUENCING"); setIsModalOpen(true); }}
            disabled={nextStatus !== "SEQUENCING"}
          >
            Sequencing
          </Button>
        </Col>
        <Col lg="3" md="4" sm="6">
          <Button
            color="info"
            onClick={() => { setStatusToChange("BIOINFO_ANALYSIS_STARTED"); setIsModalOpen(true); }}
            disabled={nextStatus !== "BIOINFO_ANALYSIS_STARTED"}
          >
            Bioinfo Analysis Started
          </Button>
        </Col>
      </Row>
      <Row>
        <Box pt={5}>
          <h3>Project's history</h3>
        </Box>
      </Row>
      <Row>
        {loading ? (
          <p>Loading...</p>
        ) : (
        <Chrono
          items={events}
          mode="VERTICAL"
          contentDetailsHeight={20}
          disableToolbar={true}
          disableInteraction={true}
          mediaHeight={0}
          cardWidth={600}
        />
        )}
      </Row>

      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Update Status to {statusToChange.replace('_', ' ')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add a comment if needed.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Comment"
            type="text"
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleStatusChange(statusToChange)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};

export default ProjectHistory;
