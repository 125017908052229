import APIService from "services/DjangoAPI";
import { Row, Col } from "reactstrap";
import { Form, Input, Button, DatePicker, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const { TextArea } = Input;
const { Option } = Select;

const CreateSequencingLibrary = () => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await APIService.getAllUsers();
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const onFinish = async (values) => {
    const sequencingLibraryData = {
      ...values,
      creation_date: values.creation_date
        ? values.creation_date.format("YYYY-MM-DD HH:mm:ss")
        : "",
    };

    try {
      console.log(sequencingLibraryData);
      const response = await APIService.createSequencingLibrary(sequencingLibraryData);
      navigate("/admin/libraries");
    } catch (error) {
      console.error("Error creating sequencing library:", error);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="8" md="6" sm="6">
            <h1>Create New Sequencing Library</h1>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please input the library name!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Description" name="description">
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item
              label="Creation Date"
              name="creation_date"
            >
              <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>

            <Form.Item
              label="Comment"
              name="comment"
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item
              label="Experimenter"
              name="experimenter"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Owner"
              name="owner"
              rules={[
                {
                  required: true,
                  message: "Please select the library owner!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select an owner"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                loading={isLoading}
              >
                {users.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.email}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </div>
    </>
  );
};

export default CreateSequencingLibrary;
