/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// react plugin used to create charts
// react plugin for creating vector maps

// reactstrap components
import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { getAuthenticatedUserId } from "tokenHelper";
import { useAuthContext } from "context/AuthContext";
import Utils from "services/Utils";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

const Genolists = () => {
  const [error, setError] = useState(null);
  const { user, isLoading, setUser } = useAuthContext();
  const authenticatedUserId = getAuthenticatedUserId();
  const [genoListslist, setGenolistslist] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const genolisttslist = await Utils.getAllGenolists();

    return {
      genolisttslist,
    };
  };

  const fetchData = async () => {
    try {
      const data = await getData(authenticatedUserId);

      setGenolistslist(data.genolisttslist);
    } catch (error) {
      console.error("Error fetching data", error);
      setError(
        "An error occurred while fetching data. Please try again. Detail : " +
          error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authenticatedUserId) {
      fetchData();
    }
  }, [user]);

  const columns = [
    { field: "id", headerName: "ID", width: 125, minWidth: 150, maxWidth: 200 },
    {
      field: "name",
      headerName: "Name",
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => (
        <Link to={`/admin/genolists/${params.row.id}`}>{params.value}</Link>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "type_genolist",
      headerName: "Type",
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "version_reference_database",
      headerName: "Reference Database",
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "public",
      headerName: "Public",
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "project.name",
      headerName: "Project",
      valueGetter: (value, row) => `${row.project.name}`,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "project.owner.email",
      headerName: "Owner",
      valueGetter: (value, row) => `${row.project.owner.email}`,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "comparison.main_factor.level",
      headerName: "Factor 1",
      valueGetter: (value, row) => `${row.comparison.main_factor.level}`,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "comparison.secondary_factor.level",
      headerName: "Factor 2",
      valueGetter: (value, row) => `${row.comparison.secondary_factor.level}`,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12" md="12" sm="12">
            <DataGrid
              rows={genoListslist}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Genolists;
