import React, { useEffect, useState, useRef } from "react";
import { Alert, CircularProgress, Typography } from "@mui/material";
import APIService from "../../services/DjangoAPI";
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

const TaskStatus = () => {
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const intervalRef = useRef(null);

  useEffect(() => {
    const taskId = localStorage.getItem("task_id");

    if (taskId) {
      const fetchStatus = async () => {
        try {
          setIsLoading(true);
          const response = await APIService.getTaskStatus(taskId);
          const taskStatus = response.data.task_status;
          setStatus(taskStatus);
          console.log(response);
          if (
            ["SUCCESS", "COMPLETED", "FAILURE", "REVOKED"].includes(taskStatus)
          ) {
            clearInterval(intervalRef.current);
            localStorage.removeItem("task_id");
            if (taskStatus === "COMPLETED" || taskStatus === "SUCCESS") {
              alert(
                "Process to populate database from the JSON file completed !",
              );
            } else if (taskStatus === "FAILURE") {
              alert(
                "Warning: the process to populate the database from the JSON file has failed.",
              );
            }
          }
        } catch (error) {
          console.error("Error fetching task status:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchStatus();
      intervalRef.current = setInterval(fetchStatus, 60000); // Poll every 60 seconds

      return () => clearInterval(intervalRef.current);
    } else {
      setIsLoading(false);
      setStatus("No task in progress");
    }
  }, []);

  return (
    <Card className="card-stats">
      <CardBody>
        <Row>
          <Col md="2" xs="3">
            <div className="icon-big text-center icon-info">
              <i className="nc-icon nc-zoom-split text-info" />
            </div>
          </Col>
          <Col md="10" xs="9">
            <div className="numbers">
              <p className="card-category">Populate database from JSON</p>
              <CardTitle tag="p">
                Current status:
                {isLoading ? " Loading..." : ` ${status}`}
              </CardTitle>
              <p />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default TaskStatus;
