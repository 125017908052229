// react plugin used to create charts
// react plugin for creating vector maps

// reactstrap components

import { Component } from "react";
import {
    Card, CardBody,
    CardFooter,
    CardTitle, Row,
    Col
} from "reactstrap";
import { Link } from "react-router-dom";


class  StatisticalCards extends Component{
    constructor(props) {
        super(props);
        this.state = {
          loading:true
        };
    }
    
    render () {
        const label = this.props.label
        const icon = this.props.icon
        const name = this.props.name
        const number = this.props.number
        const link = this.props.link
        return (
            <Card className="card-stats">
                <CardBody>
                    <Row>
                    <Col md="4" xs="5">
                        <div className={label}>
                            <i className={icon} />
                        </div>
                    </Col>
                    <Col md="8" xs="7">
                        <div className="numbers">
                        <p className="card-category">{name}</p>
                        <CardTitle tag="p">{number}</CardTitle>
                        <p />
                        </div>
                    </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                <hr />
                <div className="stats">
                    <Link  to={link}>
                    <i className="fa fa-refresh" />
                        View all
                    </Link >
                </div>
                </CardFooter>
            </Card>
        )
    }
    
}

export default StatisticalCards