/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "genolens-backoffice/pages/Dashboard.js";
import Login from "genolens-backoffice/pages/Login";
import UserProfile from "views/pages/UserProfile.js";
import Users from "genolens-backoffice/pages/Users";
import Projects from "genolens-backoffice/pages/Projects";
import Genolists from "genolens-backoffice/pages/Genolists";
import CreateProjectForm from "genolens-backoffice/pages/Create/CreateNewProject";
import CreateUserForm from "genolens-backoffice/pages/Create/CreateNewUser";
import ProjectDetails from "genolens-backoffice/pages/Views/Project";
import GenolistDetails from "genolens-backoffice/pages/Views/GenolistDetails";
import ProjectHistory from "./genolens-backoffice/pages/Views/ProjectHistory";
import CreateNewLibrary from "./genolens-backoffice/pages/Create/CreateNewLibrary";
import Libraries from "./genolens-backoffice/pages/Libraries";
import LibraryDetails from "./genolens-backoffice/pages/Views/LibraryDetails";
import UploadZipFile from "./genolens-backoffice/pages/Uploads/UploadZipFile";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-layout-11",
    component: <Dashboard />,
    private: true,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Libraries",
    icon: "nc-icon nc-book-bookmark",
    state: "librariesCollapse",
    views: [
      {
        path: "/libraries",
        name: "Libraries",
        mini: "L",
        private: true,
        component: <Libraries />,
        layout: "/admin",
      },
      {
        path: "/libraries/create",
        name: "Create new library",
        mini: "NL",
        private: true,
        component: <CreateNewLibrary />,
        layout: "/admin",
      },
      {
        path: "/libraries/upload-json",
        name: "Upload JSON from pipeline",
        mini: "U",
        private: true,
        component: <UploadZipFile />,
        layout: "/admin",
      },
      {
        path: "/libraries/:id",
        private: true,
        component: <LibraryDetails />,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Projects",
    icon: "nc-icon nc-single-copy-04",
    state: "projectCollapse",
    views: [
      {
        path: "/projects",
        name: "Projects",
        mini: "P",
        private: true,
        component: <Projects />,
        layout: "/admin",
      },
      {
        path: "/projects/create",
        name: "Create",
        mini: "CP",
        private: true,
        component: <CreateProjectForm />,
        layout: "/admin",
      },
      {
        path: "/projects/:id",
        private: true,
        component: <ProjectDetails />,
        layout: "/admin",
      },
      {
        path: "/projects/:id/history",
        private: true,
        component: <ProjectHistory />,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Genolists",
    icon: "nc-icon nc-paper",
    state: "genolistsCollapse",
    views: [
      {
        path: "/genolists",
        name: "Genolists",
        mini: "G",
        private: true,
        component: <Genolists />,
        layout: "/admin",
      },
      /*      {
        path: "/user-profile",
        name: "UserProfile",
        mini: "UP",
        private: true,
        component: <UserProfile />,
        layout: "/admin",
      },*/
      {
        path: "/genolists/:id",
        private: true,
        component: <GenolistDetails />,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Users",
    icon: "nc-icon nc-circle-10",
    state: "usersCollapse",
    views: [
      {
        path: "/users",
        name: "Users",
        mini: "U",
        private: true,
        component: <Users />,
        layout: "/admin",
      },
      /*      {
        path: "/users/create",
        name: "Create",
        mini: "UC",
        private: true,
        component: <CreateUserForm />,
        layout: "/admin",
      },*/
    ],
  },
  {
    path: "/login",
    name: "Log in",
    component: <Login />,
    layout: "/auth",
  },
];

export default routes;
