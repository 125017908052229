/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// react plugin used to create charts
// react plugin for creating vector maps

// reactstrap components
import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import { getAuthenticatedUserId } from "tokenHelper";
import { useAuthContext } from "context/AuthContext";
import Utils from "services/Utils";
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";



const Users = () => {

  const [error, setError] = useState(null);
  const { user, isLoading, setUser } = useAuthContext();
  const authenticatedUserId = getAuthenticatedUserId();
  const [userslist, setUserslist] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const userslist = await Utils.getAllUsers();

    return {
      userslist,
    };
  };

  const fetchData = async () => {
    try {
      const data = await getData(authenticatedUserId);

      setUserslist(data.userslist);
    } catch (error) {
      console.error("Error fetching data", error);
      setError("An error occurred while fetching data. Please try again. Detail : " + error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authenticatedUserId) {
      fetchData();
      
    }
  }, [user]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'first_name', headerName: 'First name', width: 130 },
    { field: 'last_name', headerName: 'Last name', width: 130 },
    { field: 'email', headerName: 'Email',},
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      width: 160,
      valueGetter: (value, row) => `${row.first_name || ''} ${row.last_name || ''}`,
    },
  ];


  return (
    <>
      <div className="content">
      <Row>
          <Col lg="8" md="6" sm="6">
            <h1>Users</h1>
          </Col>
          <Col lg="4" md="6" sm="6">
          <Link  to="/admin/users/create">
            <Button
                className="btn btn-outline-sucess btn-round"
                color="success"
                outline
            >
                <i class="fa fa-plus"></i>  New
            </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12">
            <DataGrid
              rows={userslist}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Users;
