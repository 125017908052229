//API
import APIService from "services/DjangoAPI";

class Utils {

  async getStatisticsForBackOfficeDashboard() {
    const responseProjects = await APIService.getProjectCount();
    const responseGenolists = await APIService.getGenolistCount();
    const responseUsers = await APIService.getUserCount();
    const responseLibraries = await APIService.getSequencingLibraryCount()

    return {
      projects: responseProjects.data.count,
      genolists: responseGenolists.data.count,
      users: responseUsers.data.count,
      libraries: responseLibraries.data.count,
    }
  }

  async getNumberOngoingProjectsByUser(id) {
    const { data } = await APIService.getProjectsByUser(id);
    return data.length;
  }

  async getNumberGenolistsByUser(id) {
    const { data } = await APIService.getMyGenolists();
    return data.length;
  }

  async getOccupiedDashesByUser(id) {
    const { data } = await APIService.getDashesByUser(id);
    return data.length;
  }

  async getAllUsers() {
    const { data } = await APIService.getAllUsers();
    return data; 
  }

  async getAllProjects() {
    const { data } = await APIService.getAllProjects();
    return data; 
  }

  async getProjects(id) {
    const { data } = await APIService.getProjects(id);
    console.log(data)
    return data; 
  }

  async getAllGenolists() {
    const { data } = await APIService.getGenolists();
    return data; 
  }


  /**
   *
   * @param {*} string
   * @returns string with the first letter on UpperCase
   */
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


}

export default new Utils();