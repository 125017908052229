import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { Button, Row, Col, FormGroup, Label, Input, Badge } from "reactstrap";
import moment from "moment";
import APIService from "services/DjangoAPI";
import ManageProjectsForLibrary from "../../components/modals/ManageProjectsForLibrary";
import ProjectStatusUpdateModal from "../../components/modals/ProjectStatusUpdateModal";
import { message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import UploadZipFile from "../Uploads/UploadZipFile";

const LibraryDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [libraryData, setLibraryData] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLibraryLoading, setIsLibraryLoading] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [libraryProjects, setLibraryProjects] = useState([]);
  const [isModalProjectsOpen, setIsModalProjectsOpen] = useState(false);
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [statusChangeData, setStatusChangeData] = useState({
    status: "",
    comment: "",
  });
  const [samplesheetFile, setSamplesheetFile] = useState(null);
  const [parametersheetFile, setParametersheetFile] = useState(null);
  const [conditionsFile, setConditionsFile] = useState(null);
  const [jsonFile, setJsonFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [libraryStatus, setLibraryStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState(null); // State for alert message
  const [alertSeverity, setAlertSeverity] = useState("info"); // info or error
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar visibility state

  useEffect(() => {
    const fetchLibraryData = async () => {
      try {
        const response = await APIService.getSequencingLibrary(id);
        setLibraryData(response.data);

        const projectsByLibrary =
          await APIService.getProjectsBySequencingLibrary(id);
        setLibraryProjects(projectsByLibrary.data);
      } catch (error) {
        console.error("Error fetching library data:", error);
      } finally {
        setIsLibraryLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await APIService.getAllUsers();
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsUsersLoading(false);
      }
    };

    fetchUsers();
    fetchLibraryData();
  }, [id]);

  useEffect(() => {
    // Function to determine the library status based on project statuses
    const inferLibraryStatus = () => {
      if (libraryProjects.length === 0 && isLibraryLoading === false) {
        return "Waiting for projects";
      } else if (
        libraryProjects.every(
          (project) => project.status === "SAMPLES_RECEIVED",
        )
      ) {
        return "SAMPLES_RECEIVED";
      } else if (
        libraryProjects.every(
          (project) => project.status === "PREPARING_LIBRARIES",
        )
      ) {
        return "PREPARING_LIBRARIES";
      } else if (
        libraryProjects.every((project) => project.status === "SEQUENCING")
      ) {
        return "SEQUENCING";
      } else if (
        libraryProjects.every((project) => project.status === "FAILURE")
      ) {
        return "FAILURE";
      } else if (
        libraryProjects.every((project) => project.status === "SUCCESS")
      ) {
        return "SUCCESS";
      } else if (
        libraryProjects.every((project) => project.status === "REVOKED")
      ) {
        return "REVOKED";
      } else if (
        libraryProjects.every((project) => project.status === "RETRY")
      ) {
        return "RETRY";
      } else if (
        libraryProjects.every(
          (project) => project.status === "BIOINFO_ANALYSIS_STARTED",
        )
      ) {
        return "BIOINFO_ANALYSIS_STARTED";
      }
      return "Unavailable";
    };

    // Set the inferred library status
    setLibraryStatus(inferLibraryStatus());
  }, [libraryProjects]); // Dependency array to re-run when libraryProjects changes

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setLibraryData({
      ...libraryData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const updatedLibraryData = {
        ...libraryData,
        owner: libraryData.owner.id,
      };
      await APIService.putSequencingLibrary(updatedLibraryData);
      alert("Library updated successfully");
    } catch (error) {
      console.error("Error updating library data:", error);
      alert("Failed to update library");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this library?")) {
      return;
    }
    setIsDeleting(true);
    try {
      await APIService.deleteLibrary(id);
      alert("Library deleted successfully");
      navigate("/libraries"); // Redirect to libraries list after deletion
    } catch (error) {
      console.error("Error deleting library:", error);
      alert("Failed to delete library");
    } finally {
      setIsDeleting(false);
    }
  };

  const refreshProjects = async () => {
    const projectsByLibrary =
      await APIService.getProjectsBySequencingLibrary(id);
    setLibraryProjects(projectsByLibrary.data);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleOpenStatusModal = (status) => {
    setStatusChangeData({ status, comment: "" });
    setIsModalStatusOpen(true);
  };

  const handleStatusChange = async () => {
    try {
      if (statusChangeData.status === "PREPARING_LIBRARIES") {
        const updatedLibraryData = {
          ...libraryData,
          owner: libraryData.owner.id,
          production_date: moment().toISOString(),
        };
        await APIService.putSequencingLibrary(updatedLibraryData);
        setLibraryData(updatedLibraryData);
      }

      await APIService.putProjectStatusInLibrary(id, statusChangeData);
      refreshProjects(); // Refresh projects after status update
      alert(
        `Projects status updated to '${statusChangeData.status}' successfully`,
      );
      setIsModalStatusOpen(false);
    } catch (error) {
      console.error("Error updating projects status:", error);
      alert("Failed to update projects status");
    }
  };

  // Function to handle file selection
  const handleFileChange = (event, setter) => {
    setter(event.target.files[0]);
  };

  // Upload handler
  const handleUploadMetadata = async () => {
    if (!samplesheetFile || !parametersheetFile || !conditionsFile) {
      alert("Please select all three files.");
      return;
    }
    setIsUploading(true);

    const formData = new FormData();
    formData.append("samplesheet-file", samplesheetFile);
    formData.append("param-file", parametersheetFile);
    formData.append("condition-file", conditionsFile);

    try {
      const response = await APIService.uploadLibraryMetadataFromTsv(
        id,
        formData,
      );
      alert("Metadata uploaded successfully");
    } catch (error) {
      console.error("Error uploading metadata:", error);
      alert("Failed to upload metadata");
    } finally {
      setIsUploading(false);
    }
  };

  const getStatusButton = (status, label) => {
    let disabled = false;
    if (status === "PREPARING_LIBRARIES") {
      disabled = libraryStatus !== "SAMPLES_RECEIVED";
    } else if (status === "SEQUENCING") {
      disabled = libraryStatus !== "PREPARING_LIBRARIES";
    } else if (status === "BIOINFO_ANALYSIS_STARTED") {
      disabled = libraryStatus !== "SEQUENCING";
    } else if (status === "SUCCESS") {
      disabled = libraryStatus !== "BIOINFO_ANALYSIS_STARTED";
    }

    return (
      <Button
        color="info"
        disabled={disabled}
        onClick={() => {
          setStatusChangeData({ status, comment: "" });
          setIsModalStatusOpen(true);
        }}
      >
        {label}
      </Button>
    );
  };

  const handleJsonFileChange = (info) => {
    console.log("File change event:", info); // Log file change event

    const selectedFile = info.file.originFileObj || info.file;
    console.log("Selected file:", selectedFile); // Log selected file

    // Set file state when a file is selected
    if (selectedFile) {
      setJsonFile(selectedFile);
    }

    // If the file is removed
    if (info.file.status === "removed") {
      setJsonFile(null);
    }
  };

  const handleUpload = async () => {
    if (!jsonFile) {
      message.error("Please select a file before uploading.");
      return;
    }

    const formData = new FormData();
    formData.append("file", jsonFile); // API expects a key named 'file'

    setIsUploading(true);

    try {
      const response = await APIService.uploadZipFileForLibrary(id, formData);
      alert(
        "File uploaded successfully. The task for populating database is being processed. " +
          "This task takes approximately 20 to 45 minutes. You can track the status of the task on your dashboard. " +
          "If the task fails, please contact the SciLicium team !",
      );
      localStorage.setItem("task_id", response.data.task_id);
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code that falls out of the 2xx range
        console.error("Error response from server:", error.response.status); // status code
        console.error("Response data:", error.response.data); // actual error data from the server
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request:", error.message);
      }
      alert("Failed to upload the file. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  if (isLibraryLoading || !libraryData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <div className="content">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="Library details tabs"
        >
          <Tab label="Library Details" />
          <Tab label="Attached Projects" />
          <Tab label="Library Management" />
        </Tabs>
        {tabIndex === 0 && (
          <Box sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>
              Library {id} - {libraryData.name}
            </Typography>
            <TextField
              label="Name"
              name="name"
              value={libraryData.name}
              fullWidth
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              label="Description"
              name="description"
              value={libraryData.description}
              fullWidth
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
            />
            <TextField
              label="Comment"
              name="comment"
              value={libraryData.comment}
              fullWidth
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
            />
            <TextField
              label="Metadata File URL"
              name="metadata_file_url"
              value={libraryData.metadata_file_url}
              fullWidth
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              label="Experimenter"
              name="experimenter"
              value={libraryData.experimenter}
              fullWidth
              onChange={handleChange}
              margin="normal"
            />
            <Typography variant="h6" gutterBottom>
              Dates
            </Typography>
            <TextField
              label="Creation Date"
              name="creation_date"
              value={moment(libraryData.creation_date).format(
                "YYYY-MM-DD HH:mm",
              )}
              fullWidth
              margin="normal"
              disabled
            />
            <TextField
              label="Production Date"
              name="production_date"
              type="datetime-local"
              value={
                libraryData.production_date
                  ? moment(libraryData.production_date).format(
                      "YYYY-MM-DDTHH:mm",
                    )
                  : ""
              }
              fullWidth
              onChange={handleChange}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Completion Date"
              name="completion_date"
              type="datetime-local"
              value={
                libraryData.completion_date
                  ? moment(libraryData.completion_date).format(
                      "YYYY-MM-DDTHH:mm",
                    )
                  : ""
              }
              fullWidth
              onChange={handleChange}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography variant="h6" gutterBottom>
              Quality Control Status
            </Typography>
            <Select
              name="quality_control_status"
              value={libraryData.quality_control_status || ""}
              fullWidth
              onChange={(event) =>
                setLibraryData({
                  ...libraryData,
                  quality_control_status: event.target.value,
                })
              }
              margin="normal"
            >
              <MenuItem value="FAIL">Fail</MenuItem>
              <MenuItem value="PASS">Pass</MenuItem>
              <MenuItem value="WARNING">Warning</MenuItem>
            </Select>
            <Typography variant="h6" gutterBottom>
              Owner
            </Typography>
            <Select
              name="owner"
              value={libraryData.owner?.id || ""}
              fullWidth
              onChange={(event) =>
                setLibraryData({
                  ...libraryData,
                  owner: users.find((user) => user.id === event.target.value),
                })
              }
              margin="normal"
              loading={isUsersLoading}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.email}
                </MenuItem>
              ))}
            </Select>
            <Box padding={2} display={"flex"} gap={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? "Saving..." : "Save"}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={isDeleting}
              >
                {isDeleting ? "Deleting..." : "Delete"}
              </Button>
            </Box>
          </Box>
        )}
        {tabIndex === 1 && (
          <Box sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>
              Library {id} - {libraryData.name}
            </Typography>
            <Typography variant="h5" gutterBottom>
              Attached Projects
            </Typography>
            {libraryProjects.length === 0 ? (
              <Typography>No projects attached to this library</Typography>
            ) : (
              <List>
                {libraryProjects.map((project) => (
                  <ListItem key={project.id}>
                    <ListItemText
                      primary={
                        <Link
                          to={`/admin/projects/${project.id}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {project.name}
                        </Link>
                      }
                      secondary={`Description: ${project.description} 
                      Status: ${project.status}`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsModalProjectsOpen(true)}
            >
              Manage Projects
            </Button>
            {isLibraryLoading === false &&
            libraryStatus === "Waiting for projects" ? (
              <Link to="/admin/projects/create">
                <Button variant="contained" color="success" outline>
                  <i class="fa fa-plus"></i> New
                </Button>
              </Link>
            ) : (
              <div></div>
            )}
          </Box>
        )}
        {tabIndex === 2 && (
          <Box sx={{ padding: 2 }}>
            <Row>
              <Typography variant="h4" gutterBottom>
                Library {id} - {libraryData.name}
              </Typography>
            </Row>
            <Row>
              <Typography variant="h5" gutterBottom>
                Current status:{" "}
                <Badge
                  color={"success"}
                  pill={true}
                  style={{
                    fontSize: "0.9rem",
                    padding: "0.7rem 0.7rem 0.5rem 0.7rem",
                  }}
                >
                  {libraryStatus}
                </Badge>
              </Typography>
            </Row>
            <Row>
              {getStatusButton(
                "PREPARING_LIBRARIES",
                "Confirm libraries preparation has started",
              )}
              {getStatusButton("SEQUENCING", "Confirm sequencing has started")}
              {getStatusButton(
                "BIOINFO_ANALYSIS_STARTED",
                "Confirm bioinformatics analysis has started",
              )}
              {getStatusButton(
                "SUCCESS",
                "Confirm analysis has run successfully",
              )}
            </Row>
            {libraryStatus === "SEQUENCING" ? (
              <Box sx={{ pt: 5 }}>
                <Typography variant="h6" gutterBottom>
                  Upload projects' metadata from .tsv files
                </Typography>
                <Typography>
                  Please upload the samplesheet, parametersheet and conditions
                  files.
                </Typography>
                <Box display="flex" gap={2} sx={{ pt: 5 }}>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label for="samplesheetFile">
                          Upload Samplesheet (.tsv)
                        </Label>
                        <input
                          type="file"
                          accept=".tsv"
                          onChange={(e) =>
                            handleFileChange(e, setSamplesheetFile)
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label for="parametersheetFile">
                          Upload Parametersheet (.tsv)
                        </Label>
                        <input
                          type="file"
                          accept=".tsv"
                          onChange={(e) =>
                            handleFileChange(e, setParametersheetFile)
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label for="conditionsFile">
                          Upload Conditions File (.tsv)
                        </Label>

                        <input
                          type="file"
                          accept=".tsv"
                          onChange={(e) =>
                            handleFileChange(e, setConditionsFile)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUploadMetadata}
                        disabled={isUploading}
                      >
                        {isUploading ? "Uploading..." : "Upload Metadata"}
                      </Button>
                    </Col>
                  </Row>
                  {isUploading && <LinearProgress />}
                </Box>
              </Box>
            ) : (
              <div></div>
            )}
            {libraryStatus === "SUCCESS" ? (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h5" gutterBottom>
                  Upload file containing results
                </Typography>
                <Typography variant="body" gutterBottom>
                  This file must be a .zip file and only contain a single JSON
                  file inside.
                </Typography>
                <br />
                <Upload
                  beforeUpload={() => false} // Prevent default upload behavior
                  onChange={handleJsonFileChange}
                  accept=".zip" // Restrict file type
                  showUploadList={{ showRemoveIcon: true }} // Allow file removal
                >
                  <Button icon={<UploadOutlined />}>Select ZIP File</Button>
                </Upload>
                <br />
                <Button
                  type="primary"
                  onClick={handleUpload}
                  disabled={!jsonFile || isUploading}
                  loading={isUploading}
                  style={{ marginTop: "16px" }}
                >
                  {isUploading ? "Uploading..." : "Upload"}
                </Button>
              </Box>
            ) : (
              <div></div>
            )}
          </Box>
        )}
      </div>

      <ProjectStatusUpdateModal
        open={isModalStatusOpen}
        onClose={() => setIsModalStatusOpen(false)}
        status={statusChangeData.status}
        comment={statusChangeData.comment}
        setComment={(comment) =>
          setStatusChangeData({ ...statusChangeData, comment })
        }
        onConfirm={handleStatusChange}
      />

      <ManageProjectsForLibrary
        open={isModalProjectsOpen}
        onClose={() => setIsModalProjectsOpen(false)}
        libraryId={id}
        onProjectsUpdated={refreshProjects}
      />
    </>
  );
};

export default LibraryDetails;
