/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// react plugin used to create charts
// react plugin for creating vector maps

// reactstrap components
import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { getAuthenticatedUserId } from "tokenHelper";
import { useAuthContext } from "context/AuthContext";
import StatisticalCards from "genolens-backoffice/components/Statistics-cards/StatisticalCards";
import Utils from "services/Utils";
import TaskStatus from "../components/TaskStatus";

const Dashboard = () => {
  const [error, setError] = useState(null);
  const { user, isLoading, setUser } = useAuthContext();
  const authenticatedUserId = getAuthenticatedUserId();
  const [basicStats, setBasicStats] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchCounts = async () => {
    setLoading(true);
    try {
      const data = await Utils.getStatisticsForBackOfficeDashboard();
      setBasicStats(data);
    } catch (error) {
      console.error("Error fetching data", error);
      setError(
        "An error occurred while fetching data. Please try again. Detail : " +
          error,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authenticatedUserId) {
      fetchCounts();
    }
  }, [user]);

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <StatisticalCards
              name="Users"
              number={basicStats.users}
              icon="nc-icon nc-circle-10 text-warning"
              label="icon-big text-center icon-warning"
              link="/admin/users"
            ></StatisticalCards>
          </Col>
          <Col lg="3" md="6" sm="6">
            <StatisticalCards
              name="Libraries"
              number={basicStats.libraries}
              icon="nc-icon nc-book-bookmark text-danger"
              label="icon-big text-center icon-danger"
              link="/admin/libraries"
            ></StatisticalCards>
          </Col>
          <Col lg="3" md="6" sm="6">
            <StatisticalCards
              name="Projects"
              number={basicStats.projects}
              icon="nc-icon nc-single-copy-04 text-success"
              label="icon-big text-center icon-success"
              link="/admin/projects"
            ></StatisticalCards>
          </Col>
          <Col lg="3" md="6" sm="6">
            <StatisticalCards
              name="Genolists"
              number={basicStats.genolists}
              icon="nc-icon nc-paper text-info"
              label="icon-big text-center icon-danger"
              link="/admin/genolists"
            ></StatisticalCards>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="12" sm="12">
            <TaskStatus />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
