import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button
} from "reactstrap";
import { getAuthenticatedUserId } from "tokenHelper";
import { useAuthContext } from "context/AuthContext";
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import APIService from "../../services/DjangoAPI";

const Libraries = () => {

  const [error, setError] = useState(null);
  const { user, isLoading, setUser } = useAuthContext();
  const authenticatedUserId = getAuthenticatedUserId();
  const [librariesList, setLibrariesList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await APIService.getSequencingLibraries();
      setLibrariesList(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
      setError("An error occurred while fetching data. Please try again. Detail : " + error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authenticatedUserId) {
      fetchData();
    }
  }, [user]);

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this library?")) {
      return;
    }
    try {
      await APIService.deleteSequencingLibrary(id);
      setLibrariesList(librariesList.filter((library) => library.id !== id));
      alert("Library deleted successfully");
    } catch (error) {
      console.error("Error deleting library:", error);
      alert("Failed to delete library");
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 55, minWidth: 50, maxWidth: 200 },
    { field: 'name', headerName: 'Name', width: 175, minWidth: 150, maxWidth: 200},
    { field: 'description', headerName: 'Description', width: 125, minWidth: 150, maxWidth: 200},
    {
      field: 'creation_date',
      headerName: 'Creation Date', width: 125, minWidth: 150, maxWidth: 200
    },
    {
      field: 'production_date',
      headerName: 'Production Date', width: 125, minWidth: 150, maxWidth: 200
    },
    {
      field: 'completion_date',
      headerName: 'Completion Date', width: 125, minWidth: 150, maxWidth: 200
    },
    {
      field: 'quality_control_status',
      headerName: 'QC Status', width: 125, minWidth: 150, maxWidth: 200
    },
    {
      field: 'owner',
      headerName: 'Owner',
      valueGetter: (value, row) => `${row.owner.email}`,
      width: 125, minWidth: 150, maxWidth: 200
    },
    { field: 'action',
      headerName: 'Action',
      width: 125,
      minWidth: 100,
      maxWidth: 200,
      renderCell:(cellValues) => {
        return(<Link to={"/admin/libraries/"+cellValues.row.id}>
          <Button
            className="btn btn-outline-sucess btn-round"
            color="success"
            outline
          >
            View
          </Button>
        </Link>)
      },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 150,
      renderCell: (cellValues) => {
        return (
          <Button
            className="btn btn-outline-danger btn-round"
            color="danger"
            outline
            onClick={() => handleDelete(cellValues.row.id)}
          >
            Delete
          </Button>
        );
      },
    },
  ];


  return (
    <>
      <div className="content">
        <Row>
          <Col lg="8" md="6" sm="6">
            <h1>Sequencing Libraries</h1>
          </Col>
          <Col lg="4" md="6" sm="6">
            <Link  to="/admin/libraries/create">
              <Button
                className="btn btn-outline-sucess btn-round"
                color="success"
                outline
              >
                <i className="fa fa-plus"></i>  New
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12">
            <DataGrid
              rows={librariesList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 15 },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, 50]}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Libraries;
