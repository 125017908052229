import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import APIService from '../../../services/DjangoAPI';

const ManageProjectsForLibrary = ({ open, onClose, libraryId, onProjectsUpdated }) => {
  const [attachedProjects, setAttachedProjects] = useState([]);
  const [unattachedProjects, setUnattachedProjects] = useState([]);
  const [selectedAttachProjects, setSelectedAttachProjects] = useState([]);
  const [selectedDetachProjects, setSelectedDetachProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const projectsResponse = await APIService.getProjectsWithoutLibrary();
      const attachedProjectsResponse = await APIService.getProjectsBySequencingLibrary(libraryId);
      setUnattachedProjects(projectsResponse.data);
      setAttachedProjects(attachedProjectsResponse.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchProjects();
    }
  }, [open, libraryId]);

  const handleSelectAttachProject = (projectId) => {
    setSelectedAttachProjects((prevSelected) =>
      prevSelected.includes(projectId)
        ? prevSelected.filter((id) => id !== projectId)
        : [...prevSelected, projectId]
    );
  };

  const handleSelectDetachProject = (projectId) => {
    setSelectedDetachProjects((prevSelected) =>
      prevSelected.includes(projectId)
        ? prevSelected.filter((id) => id !== projectId)
        : [...prevSelected, projectId]
    );
  };

  const handleAttachProjects = async () => {
    try {
      await APIService.attachProjectsToSequencingLibrary(libraryId, selectedAttachProjects);
      onProjectsUpdated(); // Callback to refresh the projects list in the parent component
      setSelectedAttachProjects([]);
      fetchProjects(); // Refresh project lists
      alert("Projects attached successfully");
    } catch (error) {
      console.error("Error attaching projects:", error);
      alert("Failed to attach projects");
    }
  };

  const handleDetachProjects = async () => {
    try {
      await APIService.detachProjectsFromSequencingLibrary(libraryId, selectedDetachProjects);
      onProjectsUpdated(); // Callback to refresh the projects list in the parent component
      setSelectedDetachProjects([]);
      fetchProjects(); // Refresh project lists
      alert("Projects detached successfully");
    } catch (error) {
      console.error("Error detaching projects:", error);
      alert("Failed to detach projects");
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Manage Projects for Library</DialogTitle>
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Attach Projects" />
        <Tab label="Detach Projects" />
      </Tabs>
      <DialogContent>
        {tabIndex === 0 && (
          <Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Creation Date</TableCell>
                    <TableCell>Attach</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unattachedProjects.map((project) => (
                    <TableRow key={project.id}>
                      <TableCell>{project.name}</TableCell>
                      <TableCell>{project.description}</TableCell>
                      <TableCell>{project.owner.email}</TableCell>
                      <TableCell>{new Date(project.creation_date).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={selectedAttachProjects.includes(project.id)}
                          onChange={() => handleSelectAttachProject(project.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button variant="contained" onClick={handleAttachProjects} color="primary" disabled={loading || selectedAttachProjects.length === 0}>
              Attach Selected
            </Button>
          </Box>
        )}
        {tabIndex === 1 && (
          <Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{fontWeight: 500}}>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Creation Date</TableCell>
                    <TableCell>Detach</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attachedProjects.map((project) => (
                    <TableRow key={project.id}>
                      <TableCell>{project.name}</TableCell>
                      <TableCell>{project.description}</TableCell>
                      <TableCell>{project.owner.email}</TableCell>
                      <TableCell>{new Date(project.creation_date).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={selectedDetachProjects.includes(project.id)}
                          onChange={() => handleSelectDetachProject(project.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button variant="contained" onClick={handleDetachProjects} color="primary" disabled={loading || selectedDetachProjects.length === 0}>
              Detach Selected
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageProjectsForLibrary;
