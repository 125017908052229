import React, { useState } from "react";
import { Button, Upload, message } from "antd";
import { Alert } from "reactstrap";
import APIService from "../../../services/DjangoAPI";
import { Typography, Snackbar } from "@mui/material"; // Import your API service

const UploadZipFile = () => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (info) => {
    console.log("File change event:", info); // Log file change event

    const selectedFile = info.file.originFileObj || info.file;
    console.log("Selected file:", selectedFile); // Log selected file

    // Set file state when a file is selected
    if (selectedFile) {
      setFile(selectedFile);
    }

    // If the file is removed
    if (info.file.status === "removed") {
      setFile(null);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      message.error("Please select a file before uploading.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file); // API expects a key named 'file'

    setIsUploading(true);

    try {
      const response = await APIService.uploadZipFile(formData);
      alert(
        "File uploaded successfully. The task for populating database is being processed. " +
          "This task takes approximately 20 to 45 minutes. You can track the status of the task on your dashboard." +
          "If the task fails, please contact the SciLicium team !",
      );
      localStorage.setItem("task_id", response.data.task_id);
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code that falls out of the 2xx range
        console.error("Error response from server:", error.response.status); // status code
        console.error("Response data:", error.response.data); // actual error data from the server
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request:", error.message);
      }
      alert("Failed to upload the file. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="content">
      <Typography variant="h4" gutterBottom>
        Upload file containing results
      </Typography>
      <Alert color="danger">
        This upload page should only be used in rare cases where tracking the
        samples and libraries is not necessary and we want to populate the
        database once the analysis is over. <br />
        For most cases, you need to follow the usual procedure, starting by
        creating a library.
      </Alert>
      <Typography variant="body" gutterBottom>
        This file must be a .zip file and only contain a single JSON file
        inside.
      </Typography>
      <Upload
        beforeUpload={() => false} // Prevent default upload behavior
        onChange={handleFileChange}
        accept=".zip" // Restrict file type
        showUploadList={{ showRemoveIcon: true }} // Allow file removal
      >
        <Button>Select ZIP File</Button>
      </Upload>
      <br />
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={!file || isUploading}
        loading={isUploading}
        style={{ marginTop: "16px" }}
      >
        {isUploading ? "Uploading..." : "Upload"}
      </Button>
    </div>
  );
};

export default UploadZipFile;
