import APIService from "services/DjangoAPI";
import { Row, Col } from "reactstrap";
import { Form, Input, Button, Checkbox, DatePicker, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const { TextArea } = Input;
const { Option } = Select;

const CreateProjectForm = () => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isStatusLoading, setIsStatusLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await APIService.getAllUsers();
        console.log(response.data);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchStatusOptions = async () => {
      try {
        const response = await APIService.getStatusOptionsForProject();
        setStatusOptions(response.data);
      } catch (error) {
        console.error("Error fetching status options:", error);
      } finally {
        setIsStatusLoading(false);
      }
    };

    fetchUsers();
    fetchStatusOptions();
  }, []);

  const onFinish = async (values) => {
    const projectData = {
      ...values,
      creationDate: values.creationDate
        ? values.creationDate.format("YYYY-MM-DD HH:mm:ss")
        : "",
    };

    try {
      console.log(projectData);
      const response = await APIService.createProject(projectData);
      navigate("/admin/projects");
    } catch (error) {
      console.error("Error creating project:", error);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="8" md="6" sm="6">
            <h1>Create new project</h1>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              public: false,
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please input the project name!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Description" name="description">
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item name="public" valuePropName="checked">
              <Checkbox>Public</Checkbox>
            </Form.Item>

            <Form.Item
              label="Owner"
              name="owner"
              rules={[
                {
                  required: true,
                  message: "Please select the project owner!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select an owner"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                loading={isLoading}
              >
                {users.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.email}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Creation Date" name="creationDate">
              <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>

            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "Please select the project status!",
                },
              ]}
            >
              <Select loading={isStatusLoading}>
                {statusOptions.map((status) => (
                  <Option key={status.value} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </div>
    </>
  );
};

export default CreateProjectForm;
