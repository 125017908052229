import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import APIService from "services/DjangoAPI";
import { Card } from "antd";
import { Row, Col } from "reactstrap";
import DownloadCSVButton from "genolens-backoffice/components/DownloadCSVButton";

const GenolistDetails = () => {
  const { id } = useParams();
  const [genolistData, setGenolistData] = useState("");
  const [loading, setLoading] = useState("");

  const fetchGenolistData = async () => {
    setLoading(true);
    try {
      const { data } = await APIService.getGenolistDataForBackoffice(id);
      console.log("data", data);
      setGenolistData(data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGenolistData();
  }, [id]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12">
            <Card>
              <Typography variant="h4" component="h4">
                Genolist Details
              </Typography>
              <Typography variant="h5" component="h5">
                Genolist's name: {genolistData.genolist?.name}
              </Typography>
              <Typography variant="body1" component="p">
                {genolistData.genolist?.description}
              </Typography>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Card>
              <Typography variant="h5" component="h4">
                Metadata
              </Typography>
              <Typography variant="body1" component="p">
                Belongs to the project: {genolistData.genolist?.project.name}
              </Typography>
              <Typography variant="body1" component="p">
                Owner: {genolistData.genolist?.project.owner.username} (
                {genolistData.genolist?.project.owner.email})
              </Typography>
              <Typography variant="body1" component="p">
                The project is public:{" "}
                {genolistData.genolist?.public ? "Yes" : "No"}
              </Typography>
              <Typography variant="body1" component="p">
                Pipeline version: {genolistData.genolist?.pipeline_version}
              </Typography>
              <Typography variant="body1" component="p">
                Main factor:{" "}
                {genolistData.genolist?.comparison.main_factor.level} (
                {genolistData.nb_samples_main_factor} samples)
              </Typography>
              <Typography variant="body1" component="p">
                Secondary factor:{" "}
                {genolistData.genolist?.comparison.secondary_factor.level} (
                {genolistData.nb_samples_secondary_factor} samples)
              </Typography>
            </Card>
          </Col>
          <Col lg="4">
            <Card>
              <Typography variant="h5" component="h4">
                Genes data
              </Typography>
              <Typography variant="body1" component="p">
                Total number of genes: {genolistData.nb_genes}
              </Typography>
              <Typography variant="h6" component="p">
                DEG
              </Typography>
              <Typography variant="body1" component="p">
                positive: {genolistData.genolist?.deg.pos}
              </Typography>
              <Typography variant="body1" component="p">
                negative: {genolistData.genolist?.deg.neg}
              </Typography>
              <Typography variant="body1" component="p">
                total: {genolistData.genolist?.deg.total}
              </Typography>
            </Card>
          </Col>
          <Col lg="4">
            <Card>
              <Typography variant="h5" component="h4">
                Downloads
              </Typography>
              <Typography variant="body1" component="p">
                List of over-expressed genes in either of the experimental
                conditions :
              </Typography>
              <DownloadCSVButton
                genolistId={id}
                genolistName={genolistData.genolist?.name}
                projectName={genolistData.genolist?.project.name}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GenolistDetails;
