import { useEffect, useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import Utils from "services/Utils";
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Button,
  Select
} from "@mui/material";
import moment from "moment";
import APIService from "services/DjangoAPI";

const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState(null);
  const [users, setUsers] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [isProjectLoading, setIsProjectLoading] = useState(true);
  const [isStatusLoading, setIsStatusLoading] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        console.log(id);
        const response = await Utils.getProjects(id);
        setProjectData(response);
        console.log(response);
      } catch (error) {
        console.error("Error fetching project data:", error);
      } finally {
        setIsProjectLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await APIService.getAllUsers();
        console.log(response.data);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsUsersLoading(false);
      }
    };

    const fetchStatusOptions = async () => {
      try {
        const response = await APIService.getStatusOptionsForProject();
        setStatusOptions(response.data);
      } catch (error) {
        console.error("Error fetching status options:", error);
      } finally {
        setIsStatusLoading(false);
      }
    };

    fetchUsers();
    fetchStatusOptions();
    fetchProjectData();
  }, [id]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setProjectData({
      ...projectData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const updatedProjectData = {
        ...projectData,
        owner: projectData.owner.id,
      };
      await APIService.putProject(updatedProjectData);
      alert("Project updated successfully");
    } catch (error) {
      console.error("Error updating project data:", error);
      alert("Failed to update project");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this project?")) {
      return;
    }
    setIsDeleting(true);
    try {
      await APIService.deleteProject(id);
      alert("Project deleted successfully");
      navigate("/projects"); // Redirect to projects list after deletion
    } catch (error) {
      console.error("Error deleting project:", error);
      alert("Failed to delete project");
    } finally {
      setIsDeleting(false);
    }
  };

  if (isProjectLoading || !projectData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <div className="content">
        <Box sx={{ padding: 2 }}>
          <Typography variant="h4" gutterBottom>
            Project {id} - {projectData.name}
          </Typography>
          <TextField
            label="Name"
            name="name"
            value={projectData.name}
            fullWidth
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            value={projectData.description}
            fullWidth
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
          />
          <Typography variant="h6" gutterBottom>
            Creation date
          </Typography>
          <TextField
            label="Date"
            name="creationDate"
            value={moment(projectData.creationDate).format("YYYY-MM-DD HH:mm")}
            fullWidth
            margin="normal"
          />
          <Typography variant="h6" gutterBottom>
            Status
          </Typography>
          <Select
              name="status"
              value={projectData.status}
              fullWidth
              onChange={(event) =>
                  setProjectData({ ...projectData, status: event.target.value })
              }
              margin="normal"
              loading={isStatusLoading}
          >
            {statusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
            ))}
          </Select>
          <FormControlLabel
            control={
              <Checkbox
                checked={projectData.isPublic}
                name="isPublic"
                onChange={handleChange}
              />
            }
            label="Public"
          />
          <TextField
            label="Source"
            name="source"
            value={projectData.source}
            fullWidth
            onChange={handleChange}
            margin="normal"
          >
            <MenuItem value="DOI">DOI</MenuItem>
            <MenuItem value="PMID">PMID</MenuItem>
          </TextField>
          <TextField
            label="Id access"
            name="idAccess"
            value={projectData.idAccess}
            fullWidth
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="h6" gutterBottom>
            Owner
          </Typography>
          <Select
              name="owner"
              value={projectData.owner?.id || ""}
              fullWidth
              onChange={(event) =>
                  setProjectData({
                    ...projectData,
                    owner: users.find((user) => user.id === event.target.value),
                  })
              }
              margin="normal"
              loading={isUsersLoading}
          >
            {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.email}
                </MenuItem>
            ))}
          </Select>
        </Box>
        <Box padding={2} display={"flex"} gap={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={isSaving}
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
        <Link to={`/admin/projects/${id}/history/`}>
          <Button
            variant="contained"
            color="info"
          >
            Project's history
          </Button>
        </Link>
        <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            disabled={isDeleting}
        >
          {isDeleting ? "Deleting..." : "Delete"}
        </Button>
          <Link to={`/admin/projects/`}>
            <Button
              variant="contained"
              color="secondary"
            >
              Back
            </Button>
          </Link>
        </Box>
      </div>
    </>
  );
};

export default ProjectDetails;
