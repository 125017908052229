/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// react plugin used to create charts
// react plugin for creating vector maps

// reactstrap components
import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button
} from "reactstrap";
import { getAuthenticatedUserId } from "tokenHelper";
import { useAuthContext } from "context/AuthContext";
import Utils from "services/Utils";
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import APIService from "../../services/DjangoAPI";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";

const Projects = () => {

  const [error, setError] = useState(null);
  const { user, isLoading, setUser } = useAuthContext();
  const authenticatedUserId = getAuthenticatedUserId();
  const [projectslist, setProjectlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [statusToChange, setStatusToChange] = useState("");
  const [idProjectToUpdate, setIdProjectToUpdate] = useState("");

  const getData = async () => {
    const projectslist = await Utils.getAllProjects();

    return {
        projectslist,
    };
  };

  const fetchData = async () => {
    try {
      const data = await getData(authenticatedUserId);
      console.log(data.projectslist)
      setProjectlist(data.projectslist);
    } catch (error) {
      console.error("Error fetching data", error);
      setError("An error occurred while fetching data. Please try again. Detail : " + error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authenticatedUserId) {
      fetchData();
    }
  }, [user]);

  const handleDelete = async (id) => {
      if (!window.confirm("Are you sure you want to delete this project?")) {
            return;
        }
      try {
          await APIService.deleteProject(id);
            setProjectlist(projectslist.filter((project) => project.id !== id));
            alert("Project deleted successfully");
      } catch (error) {
            console.error("Error deleting project:", error);
            alert("Failed to delete project");
      }
  };

  const handleStatusChange = async (newStatus, id) => {
    try {
      await APIService.putStatusChangeForProject(id,{
        new_status: newStatus,
        comment: comment,
      });
      await fetchData(); // Refresh events after status update
      alert(`Status updated to ${newStatus} successfully`);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating project status:", error);
      alert("Failed to update project status");
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 55, minWidth: 50, maxWidth: 200 },
    { field: 'name', headerName: 'Name', width: 195, minWidth: 150, maxWidth: 200},
    { field: 'public', headerName: 'Public', width: 75, minWidth: 70, maxWidth: 200},
    {
      field: 'creation_date',
      headerName: 'Creation Date', width: 125, minWidth: 150, maxWidth: 200
    },
    { field: 'status', headerName: 'Status', width: 245, minWidth: 150, maxWidth: 260},
    { 
        field: 'owner', 
        headerName: 'Owner',
        valueGetter: (value, row) => `${row.owner.email}`,
        width: 125, minWidth: 150, maxWidth: 200
    },
    { field: 'view',
     headerName: 'Views',
     width: 105,
     minWidth: 100,
     maxWidth: 200,
     renderCell:(cellValues) => {
      return(<Link to={`/admin/projects/${cellValues.row.id}`}>
        <Button
          className="btn btn-outline-sucess btn-round"
          color="success"
          outline
          >
            View
        </Button>
      </Link>)
      },
    },
    { field: 'project-history',
      headerName: '',
      width: 195,
      minWidth: 190,
      maxWidth: 200,
      renderCell:(cellValues) => {
        return(
          <Link to={`/admin/projects/${cellValues.row.id}/history/`}>
          <Button
            className="btn btn-outline-sucess btn-round"
            color="success"
            outline
          >
            Project's history
          </Button>
          </Link>
        )
      },
    },
    { field: 'samples',
      headerName: '',
      width: 195,
      minWidth: 190,
      maxWidth: 200,
      renderCell:(cellValues) => {
        return(
          <Button
            className="btn btn-outline-sucess btn-round"
            color="info"
            outline
            disabled={cellValues.row.status !== "PENDING"}
            onClick={() => { setStatusToChange("SAMPLES_RECEIVED"); setIsModalOpen(true); setIdProjectToUpdate(cellValues.row.id)}}
          >
            Samples Received
          </Button>
        )
      },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 150,
      renderCell: (cellValues) => {
        return (
          <Button
            className="btn btn-outline-danger btn-round"
            color="danger"
            outline
            onClick={() => handleDelete(cellValues.row.id)}
          >
            Delete
          </Button>
          );
        },
    },
  ];


  return (
    <>
      <div className="content">
      <Row>
          <Col lg="8" md="6" sm="6">
            <h1>Projects</h1>
          </Col>
          <Col lg="4" md="6" sm="6">
          <Link  to="/admin/projects/create">
            <Button
                className="btn btn-outline-sucess btn-round"
                color="success"
                outline
            >
                <i class="fa fa-plus"></i>  New
            </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12">
          <DataGrid
            rows={projectslist}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 15 },
              },
            }}
            pageSizeOptions={[5, 10, 15, 20, 50]}
          />
          </Col>
        </Row>

        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <DialogTitle>Update Status to {statusToChange.replace('_', ' ')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please add a comment if needed.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Comment"
              type="text"
              fullWidth
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsModalOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleStatusChange(statusToChange, idProjectToUpdate)} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default Projects;
